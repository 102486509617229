/* :root {
  --accent-color: #de8c4d;
  --text-color: #fff;
  --background-color: #3c5a96;
} */

:root {
  --accent-color: #ff8b00;
  --accent-color-2: #ffc60b;
  --text-color: #feffdb;
  --background-color: #444444;
}

@import url('https://patmurray.co/year/2018/fonts/stylesheet.css');

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
}

a {
  color: var(--text-color);
  transition: color, background-color 0.3s;
  background-color: transparent;
}

a:hover {
  color: var(--background-color);
  background-color: var(--text-color);
}

.container {
  margin: 0 auto;
  max-width: 900px;
  padding: 0 1em;
}

.bigtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
}

.bigtitle h1,
.bigtitle h2 {
  margin: 0;
}

.horizontal_scroll {
  overflow-x: scroll;
}

.img-fullwidth {
  width: 100%;
}

.numberGroup {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

.number-context p {
  margin: 0;
}

.number {
  min-width: 300px;
  width: 33.33333333%;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 3em
}

.number-digits {
  font-size: 5em;
  line-height: 1em;
  font-weight: 700;
  color: var(--accent-color);
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
}

@media (max-width: 414px) {
  .number-digits {
    font-size: 4em;
  }
  .embed {
    max-width: 90vw;
  }
}

.number-context {
  line-height: 1.5em
}

.number-context-figure {
  font-weight: 600;
  font-size: 1.1em
}

.number-context-title {
  font-weight: 500;
  font-size: .9em;
  text-transform: uppercase;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word
}

.number-context-info {
  font-weight: 400;
  font-size: 1em;
  line-height: 1em;
  margin-top: 10px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word
}

.unit {
  font-weight: 700
}

.number-context p em {
  box-shadow: 0 -.4em 0 var(--accent-color-2) inset;
  font-style: normal;
  white-space: nowrap;
}

.number-context p strong {
  box-shadow: 0 -.4em 0 var(--accent-color-2) inset;
  font-style: normal;
  white-space: nowrap;
}

.asside-paragraph {
  width: 300px;
  margin-right: auto;
  margin-top: 10em;
  mix-blend-mode: overlay;
}

.asside-paragraph hr {
  width: 40%;
  margin-left: 0;
}

.paragrah-normal {
  min-width: 300px;
  max-width: 400px;
  margin-right: auto;
  margin-top: 0;
  mix-blend-mode: unset;
  margin-bottom: 0;
}

.story {
  font-size: 2.5em;
  line-height: 1.6;
  margin-top: 2em;
  font-weight: 200;
  margin-bottom: 2em;
}

.story-number {
  /* font-weight: 700; */
  /* text-transform: uppercase; */
  white-space: nowrap;
  text-align: center;
  /* color: var(--accent-color); */
  border-bottom: 0.2em solid var(--accent-color);
  display: inline-block;
  line-height: 0.75;
}

.story-number-unit {
  display: inline-block;
  color: var(--text-color);
  font-weight: 500;
}

.story-number-unit-item {
  font-size: 40%;
}

.numeral {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.bold {
  font-weight: 500;
}

.heatmap-title {
  font-weight: 700;
  font-size: 1em;
  margin-bottom: 0.3em;
}

.heatmap {
  margin-bottom: 1em;
}

.matrix-flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
  justify-content: space-around;
  line-height: 30px;
}

.matrix-flex-item {
  margin: 0.5px;
  flex: 1 0 auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.matrix-flex-item:before {
  content: '';
  float: left;
  padding-top: 100%;
}

/* ACTIVITY RING */

.activity-dial-container {
  margin: 0 auto;
  /* max-width: 1500px; */
  padding: 0 1em;
  overflow: scroll;
  margin-bottom: 1em;
  padding-bottom: 1em;
}

/* @media (min-width: 1500px) {
  .activity-dial-container {
    max-width: unset;
    padding-left: calc((100vw - 1500px)/2);
    padding-right: calc((100vw - 1500px)/2);
  }
} */

.activity-dial {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  padding: 0;
  margin: 0;
}

.activity-dial circle {
  fill: none;
  stroke-width: 15px;
  stroke-linecap: round;
  -webkit-transform-origin: center;
  transform-origin: center;
  transition: stroke-dashoffset 2s linear;
}

.activity-dial .bg-1 {
  fill: black;
  r: 100px;
}

.activity-dial .circle-1 {
  stroke: #F9003D;
  r: 90px;
  stroke-dasharray: 565.2px;
}

.activity-dial .circle-2 {
  stroke: #9AFF0F;
  r: 70px;
  stroke-dasharray: 439.6px;
}

.activity-dial .circle-3 {
  stroke: #23FBEB;
  r: 50px;
  stroke-dasharray: 314px;
}

.activity-dial .inactive-1 {
  stroke: #140005;
  r: 90px;
}

.activity-dial .inactive-2 {
  stroke: #090f00;
  r: 70px;
}

.activity-dial .inactive-3 {
  stroke: #001413;
  r: 50px;
}

/* Sticky */

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
}

.fix-scroll {
  -webkit-overflow-scrolling: touch;
}

.infoBoxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
}

.box {
  box-shadow: 0 5px 25px rgba(0, 0, 0, .15);
  padding: 1em;
  max-width: 300px;
  min-height: 300px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  min-height: min-intrinsic;
  background-color: var(--accent-color);
  color: var(--background-color);
  margin: 0.5em;
}

.box h1,
.box h2,
.box h3,
.box h4,
.box h5,
.box p {
  margin: 0;
}

/* COMPARE TWO */

.CompareTwoList {
  max-width: 400px;
}

.compareTwo {
  margin-top: 1em;
  display: block;
}

.compareTwo .meter {
  position: relative;
  background: #555;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.compareTwo .meter>span.midway {
  position: absolute;
  width: 1px;
  background: var(--background-color);
  left: calc(50% - 1px);
  height: calc(100% - 7.5px);
}

.compareTwo .meter>span.carrot {
  display: block;
  height: 100%;
  position: relative;
  overflow: hidden;
  width: 5px;
  background: var(--accent-color);
  border-radius: 2.5px;
}

/* .compareTwo .word.winner {
  color: var(--accent-color);
} */

.compareTwo .word:not(.winner) {
  /* opacity: 0.6; */
  color: #777;
}

.compareTwo .word {
  text-align: center;
}

.section {
  min-height: 95vh;
  display: flex;
  align-items: center;
}

.section>.container {
  width: 100%;
}

/* Blobs */
.loading_cont {
  width: 90vw;
  height: 90vh;
  position: absolute;
  z-index: 400;
}

.bigTitle {
  z-index: 700;
}

#loader {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;

}

rect {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.blobs {
  -webkit-filter: url("#goo");
  filter: url("#goo");
}

.blob {
  fill: white;
  transition: all 3s;
}

.thumnails {
  margin-top: 1.5em;
  margin-bottom: 2em;
  line-height: 0;
  -webkit-column-count: 7;
  -webkit-column-gap: 5px;
  -moz-column-count: 7;
  -moz-column-gap: 5px;
  column-count: 7;
  column-gap: 5px
}

@media (max-width:480px) {
  .thumnails {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4
  }
}

@media (max-width:800px) {
  .thumnails {
      -webkit-column-count: 5;
      -moz-column-count: 5;
      column-count: 5
  }
}

@media (min-width:1200px) {
  .thumnails {
      -webkit-column-count: 9;
      -moz-column-count: 9;
      column-count: 9
  }
}

@media (min-width:2000px) {
  .thumnails {
      -webkit-column-count: 12;
      -moz-column-count: 12;
      column-count: 12
  }
}

.thumb {
  max-width: 100%;
  margin-bottom: 5px
}
